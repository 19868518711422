(function (canopyCore) {
	"use strict";

	canopyCore.directive("canopyCoreFieldMention", function ($sce, $timeout, $filter, controllerLinker, utilities, canopyFormFields) {
		return {
			restrict: "A",
			replace: true,
			require: "^canopyCoreFormContext",
			controller: ($scope, $element) => {
				$scope.uuid = utilities.getUUID();

				$scope.validation = {
					passed: true,
					message: ""
				};

				$scope.reference = () => {
					return $filter("canopyLocalise")("core.field.input.comment.heading");
				};

				$scope.switchToDisable = (element) => {
					$scope.disabledOnSubmit = element ? true : false;
				};

				$scope.validate = () => {
					$scope.validation.passed = true;
					$scope.validation.message = "";

					if ($scope.required) {
						if (!$scope.model) {
							$scope.validation.passed = false;
							$scope.validation.message = canopyFormFields.getFieldValidationMsg(canopyFormFields.FIELD_VALIDATION_MSG_TYPE_REQUIRED);
						}
					}

					return $scope.validation.passed;
				};

				$scope.ui = {};

				$scope.onKeyup = ($event) => {
					const selection = window.getSelection(),
						key = $event.key;

					if (key === "@") {
						$scope.ui = {
							...$scope.ui,
							showMentions: true,
							mentionCaret: selection.anchorOffset,
							mentionIndex: 0,
							mentionPattern: "",
							mentions: [...$scope.mentionUsers],
						};
						$timeout(positionPopover); // wait for popover to be rendered based on showMentions flag

					} else if (key !== "ArrowUp" && key !== "ArrowDown") {
						const text = selection.anchorNode.textContent,
							caretChar = text[$scope.ui.mentionCaret - 1];
						if (caretChar === "@") {
							const mentionPattern = text.substring($scope.ui.mentionCaret, selection.focusOffset),
								filteredMentions = $scope.mentionUsers
									.filter((user) => user.name.toLowerCase().includes(mentionPattern.toLowerCase().trim()));
							$scope.ui = {
								...$scope.ui,
								showMentions: filteredMentions.length ? true : false,
								mentionIndex: 0,
								mentionPattern: mentionPattern,
								mentions: filteredMentions,
							};
						} else {
							// @ got removed
							$scope.ui.showMentions = false;
						}
					}
				};

				$scope.onKeydown = ($event) => {
					if ($scope.ui.mentions?.length) {
						switch ($event.key) {
							case "Enter": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent newline
									$scope.addMention($scope.ui.mentions[$scope.ui.mentionIndex], $event);
								}
								break;
							}
							case "Escape": {
								$event.preventDefault();
								if ($scope.ui.showMentions) {
									$event.stopPropagation();
									$scope.ui.showMentions = false;
								}
								break;
							}
							case "ArrowUp": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent scrolling
									$scope.ui.mentionIndex = ($scope.ui.mentionIndex - 1 + $scope.ui.mentions.length) % $scope.ui.mentions.length;
									scrollMentionItemIntoView($scope.ui.mentionIndex);
								}
								break;
							}
							case "ArrowDown": {
								if ($scope.ui.showMentions) {
									$event.preventDefault(); // Prevent scrolling
									$scope.ui.mentionIndex = ($scope.ui.mentionIndex + 1) % $scope.ui.mentions.length;
									scrollMentionItemIntoView($scope.ui.mentionIndex);
								}
								break;
							}
						}
					}
				};

				$scope.addMention = (user, $event) => {
					if ($event) {
						// Weird case, a contenteditable with divs/newlines will get
						// aa invalid selection range (starat of textarea) on click on the
						// popup list.
						// 
						// So, don't do regular event interpretation
						// And, use mousedown instead of click event handler
						$event.preventDefault();
						$event.stopPropagation();
					}

					if (user) {
						// Extend range to envelope @selection
						const range = window.getSelection().getRangeAt(0);
						range.setStart(range.endContainer, range.endOffset - $scope.ui.mentionPattern.length - 1);

						const mentionHtml = `<luma-mention class="mention" contentEditable="false" title="${user.email}" data-uuid="${user.uuid}">@${$sce.getTrustedHtml(user.name)}</luma-mention>`,
							htmlNode = htmlToElement(mentionHtml);
							
						insertMentionAtCursor(htmlNode.content.firstChild);
					}
					$scope.ui = {
						...$scope.ui,
						mentionCaret: -1,
						mentionPattern: "",
						mentionIndex: 0,
						showMentions: false,
					};
				};

				$scope.onBlur = () => {
					$timeout(() => ($scope.ui.showMentions = false), 200);
				};
			
				$scope.highlightUser = (index) => {
					$scope.ui.mentionIndex = index;
				};

				$scope.$on("$destroy", () => {
					$scope.$emit("canopyFieldDestroyed");
				});

				// This function is used by the contenteditable.directive.js
				// that manages the paste event
				$scope.sanitisePaste = (paste) => {
					const html = replaceHtml(paste);
					// Create a container element to parse the HTML content
					const element = htmlToElement(html);
					insertPasteAtCursor(element.content);
				};

				// Minimal html sanitisation of the pasted content
				// TODO: User DOMPurify for better solution
				function replaceHtml(html) {
					return $sce.getTrustedHtml(html)
						// Replace any generated span tags but keep the content
						.replace(/<\/?span[^>]*>/g, "")
						// Replace style attributes with empty string
						.replace(/style=(".*?"|'.*?'|[^'">\s]+)(?=\s*?[^>]*?>)/g, "");
				}

				function scrollMentionItemIntoView(index) {
					const items = angular.element($element).find(".mention-popover li");
					items[index].scrollIntoView({behavior: "smooth", block: "nearest"});
				}

				function positionPopover() {
					const mentionPopup = document.getElementById(`${$scope.uuid}-popover`);
					if (mentionPopup) {
						const selection = window.getSelection();
						if (selection.rangeCount > 0) {
							const range = selection.getRangeAt(0),
								rect = range.getBoundingClientRect(),
								parentRect = mentionPopup.offsetParent.getBoundingClientRect();
							mentionPopup.style.left = `${(rect.left - parentRect.left - 11)}px`;
							mentionPopup.style.top = `${(rect.top - parentRect.top) + rect.height}px`;
						}
					}
				}

				function insertMentionAtCursor(htmlNode) {
					const selection = window.getSelection(),
						range = selection.getRangeAt(0),
						space = document.createTextNode("\u00a0");

					range.deleteContents(); 
					range.insertNode(htmlNode);
					range.setEndAfter(htmlNode);
					range.setStartAfter(htmlNode);
					range.insertNode(space);
					range.setEndAfter(space);
					range.setStartAfter(space);
					range.collapse(false);
					selection.removeAllRanges();
					selection.addRange(range);
				}

				function insertPasteAtCursor(htmlNode) {
					const selection = window.getSelection(),
						range = selection.getRangeAt(0);

					range.deleteContents();
					range.insertNode(htmlNode);
					range.collapse(false);
					selection.removeAllRanges();
					selection.addRange(range);
				}

				function htmlToElement(html) {
					const template = document.createElement("template");
					template.innerHTML = html.trim();
					return template;
				}
			},
			scope: {
				maxLength: "=",
				mentionUsers: "=",
				model: "=",
				placeholder: "@",
				required: "=",
			},
			link: controllerLinker,
			templateUrl: Luma.paths.context + "/system/mantle/components/canopyCore/directives/form-field/canopy-form-field-mention.template.html"
		};
	});
})(canopyCore);