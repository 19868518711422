(function (canopyCore) {
	"use strict";

	canopyCore.directive("contenteditable", function($sce) {
		return {
			restrict: "A",
			require: "ngModel",
			link: function(scope, element, attrs, ngModel) {
				// View to Model
				function writeToModel() {
					let html = element.html();
					if (html === "<br>") {
						html = "";
					}
					ngModel.$setViewValue(html);
				}

				element.on("blur keyup change", blurKeyupChangeEvent);
	
				element.on("paste", pasteEvent);

				element.on("$destroy", () => {
					element.off("blur change", blurKeyupChangeEvent);
					element.off("paste", pasteEvent);
				});

				// Model to View
				ngModel.$render = () => {
					element.html($sce.getTrustedHtml(ngModel.$viewValue || ""));
				};

				// Initialise
				ngModel.$setViewValue(scope[attrs.ngModel]);
				ngModel.$render();

				function blurKeyupChangeEvent() {
					scope.$evalAsync(writeToModel);
				}

				function pasteEvent($event) {
					if (scope.sanitisePaste) {
						$event.preventDefault();
						// Get the clipboard data
						const clipboardData = $event.originalEvent.clipboardData,
							paste = clipboardData.getData("text/html") || clipboardData.getData("text/plain");
						// Declare in parent directive
						scope.sanitisePaste(paste);
					}
				}
			}
		};
	});
})(canopyCore);